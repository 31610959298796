<template>
  <div class="device-label" v-if="value">
    <span :class="[colorClass]">{{ formattedValue }}</span>
  </div>
</template>

<script>

const temperatureRoom = [
  { value: 30, color: 'dark-red' },
  { value: 25, color: 'red' },
  { value: 23, color: 'yellow' },
  { value: 20, color: 'green' },
  { value: 15, color: 'teal' },
  { value: 0, color: 'dark-blue' }
];

const co2Colors = [
  { value: 5000, color: 'red' },
  { value: 2000, color: 'orange' },
  { value: 1000, color: 'yellow' },
  { value: 700, color: 'olive' },
  { value: 400, color: 'green' },
  { value: 50, color: 'teal' },
]

export default {
  name: 'TemperatureLabel',
  props: {
    value: Number,
    type: String,
    online: Boolean,
  },
  computed: {
    colorClass: function () {
      if (this.hasNotValue()) {
        return 'gray';
      }

      if (this.type === 'temperature') {
        return this.valueToColorClass(temperatureRoom);
      } else if (this.type === 'co2') {
        return this.valueToColorClass(co2Colors);
      } else {
        return 'gray'
      }
    },
    formattedValue: function () {
      if (this.type.indexOf('temperature') === 0 && !isNaN(this.value)) {
        return this.value.toFixed(1)
      } else {
        return this.value;
      }
    }
  },
  methods: {
    valueToColorClass(colorMap) {
      return colorMap.find(x => x.value <= this.value).color
    },
    hasNotValue() {
      return this.value === null || this.value === undefined || !this.online
    }
  }
};
</script>

<style scoped>

</style>
