<template>
  <div class="device-label progress">
    <div class="bar" :style="{background: color, width: percent + '%'}">
      <span>{{ percent }}%</span>
    </div>
  </div>
</template>

<script>

const humidityColors = [
  { value: 65, color: "purple" },
  { value: 60, color: "teal" },
  { value: 30, color: "#689f38" },
  { value: 10, color: "#fbc02d" },
  { value: 0, color: "read" }
];

export default {
  name: "ProgressLabel",
  props: {
    value: Number,
    online: Boolean,
  },
  computed: {
    percent: function () {
      return this.value >= 128 ? this.value - 128 : this.value;
    },
    color: function () {
      if (!this.online || this.value === null || this.value === undefined) {
        return 'gray'
      }

      return humidityColors.find(x => x.value <= this.value).color
    }
  }
}

</script>

<style scoped lang="scss">

.progress {
  display: block;
  line-height: 2;
  background-color: #c1c1c1;
  border-radius: 4px;

  .bar {
    width: 0;
    min-width: 2em;
    color: white;
    font-weight: bold;
    border-radius: 4px;
  }
}

</style>
