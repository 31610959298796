<template>
  <page :title="buildingName">
    <div slot="content" class="p-4">
      <!--vue-hcaptcha
        v-if="!captchaToken"
        sitekey="5dfbeaed-495c-4195-b113-1e17cb99bd5f"
        @verify="onCaptchaVerify"
        @expired="onBadCaptcha"
        @challengeExpired="onBadCaptcha"
        @error="onBadCaptcha"
      /-->
      <template>
        <m-data-table :columns="tableColumns"
                      :data-source="devices"
                      :searchColumns="['roomGroupName']"
                      rowKey="id"
                      class="m-table device-table"
        >
          <template v-slot:record="{record}">
            <td v-for="field in tableColumns">
              <template v-if="field.dataIndex==='roomGroupName'">
                <link-badge link="device-charts" :params="{id: record['id']}" :title="record['roomGroupName']"/>
              </template>
              <template v-else-if="field.component==='ProgressLabel'">
                <progress-label :value="record[field.dataIndex]" :online="record['online']"/>
              </template>
              <template v-else-if="field.component==='TemperatureLabel'">
                <temperature-label :value="record[field.dataIndex]" :online="record['online']" :type="field.dataIndex"/>
              </template>
              <template v-else>
                {{ record[field.dataIndex] }}
              </template>
            </td>
          </template>
        </m-data-table>
      </template>
    </div>
  </page>
</template>

<script>

import ProgressLabel from '@/components/device/label/ProgressLabel'
import TemperatureLabel from '@/components/device/label/TemperatureLabel'
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

const tableColumns = [
  {
    title: 'Telpas nosaukums',
    dataIndex: 'roomGroupName'
  },
  {
    title: 'CO<sub>2</sub> līmenis',
    dataIndex: 'co2',
    dataType: 'Double',
    component: 'TemperatureLabel',
  },
  {
    title: 'Temperatūra C<sup>0</sup>',
    dataIndex: 'temperature',
    dataType: 'Double',
    component: 'TemperatureLabel'
  },
  {
    title: 'Telpas relatīvais mitrums',
    dataIndex: 'humidity',
    dataType: 'Double',
    component: 'ProgressLabel'
  },
]

export default {
  name: "Buildings",
  components: {
    ProgressLabel,
    TemperatureLabel,
    VueHcaptcha
  },
  data() {
    const buildingId = this.$route.params.id;
    return {
      baseUrl: '/api/device/',
      tableColumns,
      buildingId,
      buildingName: null,
      devices: [],
      captchaToken: null,
    }
  },
  computed: {
    routeTab: function () {
      return this.buildingName
    }
  },
  async created() {
    await this.loadBuilding()
    await this.loadBuildingDevices();
  },
  methods: {
    async loadBuilding() {
      const resp = await this.$http.get('/api/building/get/' + this.buildingId)
      this.buildingName = resp.data.name
    },
    async loadBuildingDevices() {
      const req = {
        buildingId: this.buildingId,
        captchaToken: this.captchaToken
      }
      const resp = await this.$http.post(this.baseUrl + 'list', req)
      this.devices = resp.data
    },
    async onCaptchaVerify(token, ekey) {
      this.captchaToken = token;
      await this.loadBuildingDevices();
    },
    onBadCaptcha() {
      this.captchaToken = null;
      this.devices = []
    }
  }
}
</script>

<style scoped lang="scss">

.device-table {
  max-width: 840px;
}


</style>
